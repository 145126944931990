import React from 'react';

import HostingImg from '../../images/hosting.svg';

import './DomainBox.style.scss';

const DomainBox = ({domainName}) => {
    return (
        <div className="domain-box">
            <div className="domain-box__icon">
                <img src={HostingImg} alt=""/>
            </div>
            <p className="domain-box__domain">{domainName}</p>
            <p className="domain-box__text">Webová stránka pro tuto doménu buď neexistuje nebo se právě vyrábí.</p>
        </div>
    )
};

export default DomainBox
