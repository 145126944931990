import React from 'react';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import DomainBox from '../components/DomainBox/DomainBox';

const Index = () => {
    const domainName = typeof window !== 'undefined' ? window.location.hostname : '';

    return (
        <Layout>
            <SEO title={domainName} />
            <DomainBox domainName={domainName}/>
        </Layout>
    )
};

export default Index;
